import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import "./ShowMoreWrapper.less"

const ShowMoreWrapper = ({ height = 200, children }) => {
  const { t } = useTranslation()

  const [showingContent, setShowingContent] = useState(false)

  const [wrapperStyle, setWrapperStyle] = useState({})

  useEffect(() => {
    if (showingContent) {
      setWrapperStyle({
        maxHeight: "initial",
      })
    } else {
      setWrapperStyle({
        maxHeight: height,
        overflow: "hidden",
      })
    }
  }, [showingContent, height])

  return (
    <div className="show-more-wrapper" style={{ ...wrapperStyle }}>
      {children}
      <div className="bottom-sheet">
        {showingContent ? (
          <div>
            <Button
              className="yellowsp"
              onClick={() => {
                setShowingContent(false)
              }}
            >
              {t("button:hide")}
            </Button>
          </div>
        ) : (
          <div>
            <Button
              className="yellowsp"
              onClick={() => {
                setShowingContent(true)
              }}
            >
              {t("button:showMore")}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ShowMoreWrapper
