import React, { useEffect } from "react"
import PageRoot from "../components/PageRoot"
import { graphql } from "gatsby"
import CourseDetailHeader from "../components/CourseDetailHeader"
import CourseVideoList from "../components/CourseVideoList"
import ContainerWithPadding from "../components/ContainerWithPadding"
import withOwnershipCheck from "../components/withOwnershipCheck"
import { getImageUrl, sendViewItemEvent } from "../utils"
import ReadOnlyQuill from "../components/ReadOnlyQuill"
import { Col, Row, Timeline } from "antd"
import _ from "lodash"
import Faq from "../components/Faq"
import { useTranslation } from "react-i18next"
import useOwnerShipCheck from "../hooks/useOwnerShipCheck"
import AuthorListDetail from "../components/AuthorListDetail"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"
import ShowMoreWrapper from "../components/ShowMoreWrapper"

const CourseDetailHeaderWithCheck = withOwnershipCheck(CourseDetailHeader)

const CourseDetailPage = ({ data }) => {
  const { t } = useTranslation()
  const course = data.ats.courses_by_pk
  const {
    title,
    subtitle,
    short_description,
    description,
    videos,
    show_faq,
    faq,
    faq_json,
    show_learning_path,
    learning_path,
    landscape,
    seo,
    content_index,
  } = course

  const allAuthors = videos.reduce((accumulator, { video }) => {
    return [...accumulator, ...video.author_rels.map(item => item.author)]
  }, [])

  const authorList = _.uniqBy(allAuthors, "id")

  const { canPlay } = useOwnerShipCheck(course)

  useEffect(() => {
    sendViewItemEvent(course)
  }, [])

  const seoImage = getImageUrl(landscape, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  return (
    <PageRoot
      title={seo.title}
      description={seo.description}
      image={process.env.GATSBY_AUTH0_REDIRECT_URI + seoImage}
    >
      <div id="intro">
        <CourseDetailHeaderWithCheck data={course} />
      </div>
      <ContainerWithPadding align={"start"} size={"large"}>
        <Row gutter={26} className="data-video-container">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            xxl={{ span: 14, offset: 2 }}
          >
            <div
              id="description"
              className="row-info product-description-wrapper"
            >
              <h2>{t("label:courseDescription")}</h2>
              <hr />
              <ReadOnlyQuill value={description} />
            </div>

            <div id="materials" className="row-info">
              <h2>{t("label:modulesAndMaterials")}</h2>
              <CourseVideoList videos={videos} ownIt={canPlay} />
            </div>

            {content_index?.length > 0 && (
              <div id="contentIndex" className="row-info">
                <h2>{t("label:contentIndex")}</h2>
                <hr />
                <ShowMoreWrapper>
                  <Timeline>
                    {content_index.map((content, index) => (
                      <Timeline.Item color="yellow" key={index}>
                        {content}
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </ShowMoreWrapper>
              </div>
            )}

            {show_learning_path && (
              <div id="learning" className="row-info">
                <h2>{t("label:whatGoingToLearn")}</h2>
                <hr />
                <Row gutter={26}>
                  <Col xs={24} sm={24} md={12}>
                    <GatsbyImage
                      image={getImage(landscape?.localFile)}
                      alt={title || "cover"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <ReadOnlyQuill value={learning_path} />
                  </Col>
                </Row>
              </div>
            )}

            <div id="authors" className="row-info">
              <h2>{t("label:authors")}</h2>
              <hr />
              <AuthorListDetail data={authorList || []} />
              {/*<AtsGrid*/}
              {/*  colSettings={{ span: 24 }}*/}
              {/*  dataSource={authorList}*/}
              {/*  renderItem={item => (*/}
              {/*    <AuthorTile data={item} direction="horizontal" />*/}
              {/*  )}*/}
              {/*/>*/}
            </div>

            {show_faq && (
              <div id="faq" className="row-info">
                <h2>{t("label:faq")}</h2>
                <hr />
                {/*<ReadOnlyQuill value={faq} />*/}
                <Faq data={faq_json} />
              </div>
            )}
            {/*<div id="certification" className="row-info">*/}
            {/*  <h2>Certificazione online</h2>*/}
            {/*  <hr />*/}
            {/*</div>*/}
          </Col>
        </Row>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query getCourse($courseId: ats_uuid!) {
    ats {
      courses_by_pk(id: $courseId) {
        ...PreviewCardCourse
        description
        show_faq
        faq
        faq_json
        show_learning_path
        learning_path
        content_index
        seo {
          title
          description
          slug
        }
        videos(
          order_by: { index: asc }
          distinct_on: index
          where: {
            video: {
              _or: [
                { state: { _eq: PUBLISHED } }
                { state: { _eq: COMING_SOON } }
              ]
            }
          }
        ) {
          video {
            ...PreviewCardVideo
            author_rels {
              author {
                ...PreviewCardAuthor
                short_biography
              }
            }
            attachments {
              name
              attachment {
                filename
                s3_key
              }
            }
          }
        }
        header_image {
          ...SvgImage
        }
        wallpaper {
          is_public
          s3_key
        }
      }
    }
  }
`

export default CourseDetailPage
