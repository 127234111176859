import React from "react"
import "./CourseVideoList.less"
import { Badge, Col, List, Row, Space } from "antd"
import { AuthorList, ComingSoon, Duration } from "./Labels"
import { navigate } from "gatsby"
import { translatePath } from "../utils"
import { COMING_SOON } from "../enums/RecordStates"
import AttachmentList from "./AttachmentList"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FREE } from "../enums/Plans"
import { useTranslation } from "react-i18next"
import { ON_DEMAND, SUBSCRIPTION } from "../enums/PricePolicies"
import useBreakpoint from "../hooks/useBreakpoint"
import { useAuth0 } from "@auth0/auth0-react"

const CourseVideoList = ({ videos, ownIt }) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint === "xs" || breakpoint === "sm"
  const { isAuthenticated } = useAuth0()

  const renderItem = item => {
    const { video } = item
    if (!video) {
      return null
    }

    const {
      id,
      title,
      subtitle,
      short_description,
      landscape,
      attachments,
      duration,
      seo,
      state,
      available_date,
      price_policy,
    } = video

    const handleVideoTileClick = () => {
      if (!ownIt) {
        switch (price_policy) {
          case FREE:
            break
          case ON_DEMAND:
            navigate("#intro")
            return
          case SUBSCRIPTION:
            navigate("/subscribe")
            return
        }
      }

      if (state !== COMING_SOON) {
        navigate(`${translatePath("/watch")}/${seo?.slug}`)
      }
    }

    // const optimizedImage = (
    //   <GatsbyImage
    //     style={{ width: "100%" }}
    //     image={getImage(landscape?.localFile)}
    //     alt={title || "cover"}
    //   />
    // )

    const optimizedImage = (
      <img
        style={{ width: "100%" }}
        src={`/.netlify/functions/get-download-url?key=${landscape.s3_key}&size=500x281`}
        alt={title || "cover"}
      />
    )

    let previewImage = optimizedImage

    if (!ownIt && video.price_policy !== FREE) {
      previewImage = (
        <div>
          {optimizedImage}
          <div className="lock-overlay">
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: "auto",
                width: "80%",
                height: isMobile ? 30 : 60,
                textAlign: "center",
              }}
            >
              <Space direction="vertical">
                <img
                  src="/assets/images/lock.svg"
                  alt="lock"
                  className="lock-icon"
                />
                {!isMobile && (
                  <b>
                    {video.price_policy === SUBSCRIPTION ? (
                      <>
                        <span>{t("label:includedInSubscription")}</span>
                        <br />
                        <Badge
                          className="subscription-page"
                          style={{ backgroundColor: "#EC9A0C" }}
                          count={t("label:premiumMembership")}
                        />
                      </>
                    ) : (
                      <>
                        <span>{t("label:needPurchase")}</span>
                        <br />
                        <Badge
                          className="subscription-page"
                          style={{ backgroundColor: "#9a0cec" }}
                          count={t("label:purchase")}
                        />
                      </>
                    )}
                  </b>
                )}
              </Space>
            </div>
          </div>
        </div>
      )
    }

    if (video.price_policy === FREE && !isAuthenticated) {
      previewImage = (
        <div>
          {optimizedImage}
          <div className="lock-overlay">
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: "auto",
                width: "80%",
                height: isMobile ? 30 : 60,
                textAlign: "center",
              }}
            >
              <Space direction="vertical">
                <img
                  src="/assets/images/lock.svg"
                  alt="lock"
                  className="lock-icon"
                />
                {!isMobile && (
                  <b>
                    <span>{t("label:needLogin")}</span>
                    <Badge className="subscription-page" count={"Free"} />
                  </b>
                )}
              </Space>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="course-video-list-wrapper">
        <hr />
        <Row gutter={26}>
          <Col span={8}>
            <a onClick={handleVideoTileClick}>
              <div className="course-video-preview">{previewImage}</div>
            </a>
          </Col>
          <Col span={16}>
            <h3>
              <a onClick={handleVideoTileClick}>{title}</a>
            </h3>
            <p>{short_description}</p>
            <Space direction="horizontal">
              {state === COMING_SOON ? (
                <ComingSoon availableDate={available_date} />
              ) : (
                <Duration totalTime={duration} />
              )}
              <AuthorList authorList={[]} />
            </Space>
            <Row>
              <Col span={24}>
                {ownIt && <AttachmentList dataSource={attachments} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div>
      <List dataSource={videos} renderItem={renderItem} />
    </div>
  )
}

export default CourseVideoList
