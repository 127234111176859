import {
  isSubscriptionValid,
  isUndefined,
  mapItemTypeFromTypename,
} from "../utils"
import { useEffect, useState } from "react"
import useFavorite from "./useFavorite"
import { useAuth0 } from "@auth0/auth0-react"
import { useGlobalValue } from "./useGlobalValue"
import useCommonQueries from "./useCommonQueries"
import { FREE, ON_DEMAND, SUBSCRIPTION } from "../enums/PricePolicies"
import { LAWFUL_SUBSCRIPTION_ROLES } from "../enums/UserRoles"

const useOwnerShipCheck = data => {
  const itemId = data.id
  const itemType = mapItemTypeFromTypename(data.__typename)
  const isVideo = data.__typename === "ats_videos"

  const [itemToCheck, setItemToCheck] = useState(null)
  const [loading, setLoading] = useState(true)
  const [canPurchase, setCanPurchase] = useState(false)
  const [canPlay, setCanPlay] = useState(false)
  const [progress, setProgress] = useState(0)
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false)
  const [isFavorite, setIsFavorite] = useFavorite(itemId, itemType)

  const { isAuthenticated } = useAuth0()

  const [{ userInventory, userSubscription, user, isLoadingUser }] =
    useGlobalValue()
  const {
    createPurchaseIntent,
    getVideoHistory,
    getLastWatchVideoIdInCourse,
    getLastWatchVideoSlugInCourse,
    getCourseProgress,
  } = useCommonQueries()

  const calcVideoProgress = async () => {
    try {
      const time = await getVideoHistory({ videoId: data.id })
      const progress = (time / data.duration) * 100
      setProgress(progress)
    } catch (error) {
      console.error(error)
      setProgress(0)
    }
  }

  const calcCourseProgress = async () => {
    try {
      const _progress = await getCourseProgress({ courseId: data.id })
      setProgress(_progress)
    } catch (error) {
      setProgress(0)
    }
  }

  useEffect(() => {
    if (itemToCheck?.__typename === "ats_certifications") {
      if (!isUndefined(userInventory)) {
        if (
          userInventory.filter(({ item_id }) => itemToCheck.id === item_id)
            .length
        ) {
          setCanPlay(true)
          setCanPurchase(false)
        } else {
          setCanPlay(false)
          setCanPurchase(true)
        }
        setLoading(false)
      }
    }
  }, [itemToCheck, userInventory, user])

  useEffect(() => {
    if (itemToCheck?.price_policy === FREE) {
      setLoading(false)
      setCanPlay(true)
      setCanPurchase(false)
    }
  }, [itemToCheck])

  useEffect(() => {
    if (itemToCheck?.price_policy === ON_DEMAND) {
      if (!isUndefined(userInventory)) {
        if (
          userInventory.filter(({ item_id }) => itemToCheck.id === item_id)
            .length
        ) {
          setCanPlay(true)
          setCanPurchase(false)
        } else {
          setCanPlay(false)
          setCanPurchase(true)
        }
        setLoading(false)
      }
    }
  }, [userInventory, itemToCheck, user])

  useEffect(() => {
    if (itemToCheck?.price_policy === SUBSCRIPTION) {
      if (LAWFUL_SUBSCRIPTION_ROLES.includes(user?.role)) {
        setCanPlay(true)
        setCanPurchase(false)
        setLoading(false)
        return
      }

      if (isSubscriptionValid(userSubscription)) {
        setCanPlay(true)
        setCanPurchase(false)
        setHasActiveSubscription(true)
      } else {
        setCanPlay(false)
        setCanPurchase(true)
        setHasActiveSubscription(false)
      }
      setLoading(false)
    }
  }, [userSubscription, itemToCheck])

  useEffect(() => {
    if (!itemToCheck) {
      return
    }

    if (
      ["ats_certifications", "certifications"].includes(itemToCheck.__typename)
    ) {
      return
    }

    if (isVideo) {
      calcVideoProgress()
    } else {
      calcCourseProgress()
    }
  }, [canPlay, itemToCheck])

  useEffect(() => {
    let temp
    if (data.__typename === "ats_courses") {
      temp = data
    } else {
      temp = data?.course_rel?.course ?? data
    }
    setItemToCheck(temp)
  }, [data])

  return {
    canPlay,
    canPurchase,
  }
}

export default useOwnerShipCheck
