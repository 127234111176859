import React from "react"
import { devLog, getDownloadUrl } from "../utils"
import { List } from "antd"
import { DownloadOutlined, FileProtectOutlined } from "@ant-design/icons"
import Avatar from "antd/es/avatar/avatar"
import { useTranslation } from "react-i18next"

const AttachmentList = ({ dataSource }) => {
  devLog({ dataSource })
  const { t } = useTranslation()

  if (!dataSource?.length) {
    return null
  }

  return (
    <>
      <List
        header={<h4>{t("label:materialsForDownload")}</h4>}
        className="attachment-list"
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={({ attachment }, index) => {
          const downloadUrl = getDownloadUrl(attachment.s3_key)
          return (
            <List.Item
              key={index}
              actions={[
                <a href={downloadUrl} target="_blank">
                  <DownloadOutlined />
                </a>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar size={32} icon={<FileProtectOutlined />} />}
                title={
                  <a href={downloadUrl} target="_blank">
                    {attachment.filename}
                  </a>
                }
              />
            </List.Item>
          )
        }}
      />
    </>
  )
}

export default AttachmentList
