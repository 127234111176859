import React, { useMemo } from "react"
import "./CourseDetailHeader.less"
import { Affix, Anchor, Col, Progress, Row, Skeleton, Space } from "antd"
import { AuthorList, Level, VideoCount } from "./Labels"
import { defaultTheme } from "../theme"
import HeaderExtra from "./HeaderExtra"
import PropTypes from "prop-types"
import { FavoriteButton, PlayButton, PurchaseButton } from "./Buttons"
import _ from "lodash"
import { useImageColorDetector } from "../hooks/useImageColorDetector"
import { useTranslation } from "react-i18next"
import { PUBLISHED } from "../enums/RecordStates"
import { devLog } from "../utils"
import { SUBSCRIPTION } from "../enums/Plans"
import InstallmentWidget from "./payment/InstallmentWidget"

const CourseDetailHeader = ({
  data,
  loading,
  canPurchase,
  canPlay,
  progress = 0,
  onToggleFavorite,
  onPlay,
  onPurchase,
  showFavoriteButton,
  isFavorite,
}) => {
  const {
    title,
    subtitle,
    short_description,
    videos,
    price,
    price_policy,
    special_price,
    header_image,
    wallpaper,
    show_faq,
    show_learning_path,
    content_index,
    level,
  } = data

  devLog({ data, special_price })

  const wallpaperUrl = `/.netlify/functions/get-download-url?key=${wallpaper.s3_key}&size=1920x1080`

  const { isDark } = useImageColorDetector({
    url: wallpaper?.localFile?.childImageSharp.fixed.src || wallpaperUrl,
  })

  const hasVideo = videos[0]?.video?.state === PUBLISHED

  const { t } = useTranslation()

  const { Link } = Anchor

  const allAuthors = videos.reduce((accumulator, { video }) => {
    if (!video) {
      return [...accumulator]
    }
    return [...accumulator, ...video.author_rels.map(rel => rel.author)]
  }, [])

  const authorList = _.uniqBy(allAuthors, "id")

  const availableForPaypalCredit = useMemo(() => {
    return canPurchase && price_policy !== SUBSCRIPTION
  }, [canPurchase, price_policy])

  return (
    <div
      className="content-detail-header course-detail-header"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , url(${
          wallpaper?.localFile?.childImageSharp.fixed.src ||
          wallpaperUrl ||
          defaultTheme.defaultWallpaper
        })`,
      }}
    >
      <div className={`recap-wrapper content ${isDark ? "dark" : "light"}`}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="wrapper-title-image">
              <img src={header_image?.localFile?.publicURL} alt={title} />
            </div>
            <div className="wrapper-title-info">
              {loading ? (
                <Skeleton active />
              ) : (
                <>
                  <Progress percent={progress} showInfo={false} />
                  <Space align={"start"} wrap>
                    {canPlay && hasVideo && (
                      <PlayButton
                        onClick={() => {
                          onPlay && onPlay(data)
                        }}
                      />
                    )}
                    {canPurchase && (
                      <PurchaseButton
                        price={price}
                        pricePolicy={price_policy}
                        specialPrice={special_price}
                        onClick={() => {
                          onPurchase && onPurchase(data)
                        }}
                      />
                    )}
                    {showFavoriteButton && (
                      <FavoriteButton
                        onClick={() => {
                          onToggleFavorite && onToggleFavorite(data)
                        }}
                        isFavorite={isFavorite}
                      />
                    )}
                  </Space>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="detail-wrapper">
        <Row gutter={60}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <h1 className="title">{title}</h1>
            <h2 className="subtitle">{subtitle}</h2>
            <p className="description">{short_description}</p>
            <div>
              <Space direction="horizontal">
                <div className="each-item">
                  <AuthorList authorList={authorList} />
                </div>
                <div className="each-item">
                  <VideoCount count={videos.length} />
                </div>
                <div className="each-item">
                  <Level level={level} />
                </div>
              </Space>
            </div>
            <HeaderExtra />
          </Col>
          <Col sm={24} xs={24} md={24} lg={8} xl={8} xxl={8}>
            <Affix className="affix-wrapper">
              <div className="side-nav-wrapper">
                <div className="single-action">
                  <Space>
                    {canPlay && hasVideo && (
                      <PlayButton
                        onClick={() => {
                          onPlay && onPlay(data)
                        }}
                      />
                    )}
                    {canPurchase && (
                      <PurchaseButton
                        price={price}
                        pricePolicy={price_policy}
                        specialPrice={special_price}
                        onClick={() => {
                          onPurchase && onPurchase(data)
                        }}
                      />
                    )}
                  </Space>
                </div>
                {availableForPaypalCredit && (
                  <InstallmentWidget
                    onPurchaseClick={() => {
                      onPurchase && onPurchase(data)
                    }}
                    price={price}
                  />
                )}
                <Anchor className="hookers">
                  <Link href="#intro" title={t("label:intro")} />
                  <Link href="#description" title={t("label:description")} />
                  <Link href="#materials" title={t("label:materials")} />
                  {content_index && (
                    <Link
                      href="#contentIndex"
                      title={t("label:contentIndex")}
                    />
                  )}
                  {show_learning_path && (
                    <Link
                      href="#learning"
                      title={t("label:whatGoingToLearn")}
                    />
                  )}
                  <Link href="#authors" title={t("label:authors")} />
                  {show_faq && <Link href="#faq" title={t("label:faq")} />}
                  {/*<Link href="#certification" title="Certificazione online" />*/}
                </Anchor>
              </div>
            </Affix>
          </Col>
        </Row>
      </div>
    </div>
  )
}

CourseDetailHeader.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  canPurchase: PropTypes.bool,
  canPlay: PropTypes.bool,
  onPurchase: PropTypes.func,
  onPlay: PropTypes.func,
  onToggleFavorite: PropTypes.func,
  isFavorite: PropTypes.bool,
  showFavoriteButton: PropTypes.bool,
  progress: PropTypes.number,
}

export default CourseDetailHeader
