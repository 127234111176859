import React from "react"
import { Collapse } from "antd"
import "./Faq.less"
import NoDataScreen from "./NoDataScreen"

const { Panel } = Collapse

const Faq = ({ data }) => {
  if (!data) {
    return <NoDataScreen />
  }
  return (
    <div className="faq-wrapper">
      <Collapse accordion>
        {data.map(({ question, answer }, index) => (
          <Panel header={question} key={index}>
            <p>{answer}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

export default Faq
