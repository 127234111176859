import React, { useMemo } from "react"
import { Col, Row, Space } from "antd"

import "./AuthorListDetail.less"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { defaultTheme } from "../theme"
import { VideoCount } from "./Labels"
import { devLog, getImageUrl, translatePath } from "../utils"
import { navigate } from "gatsby"

const imageColSettings = {
  xs: 4,
  sm: 4,
  md: 4,
  lg: 4,
  xl: 4,
  xxl: 4,
}

const contentColSettings = {
  xs: 20,
  sm: 20,
  md: 20,
  lg: 20,
  xl: 20,
  xxl: 20,
}

const AuthorRow = ({ data }) => {
  const {
    id,
    firstname,
    lastname,
    title,
    biography,
    short_biography,
    picture,
    video_rels,
    seo,
  } = data

  const imageSource = getImageUrl(picture)

  const coverElement = useMemo(() => {
    if (imageSource?.localFile?.childImageSharp?.gatsbyImageData) {
      const imageData = getImage(imageSource?.localFile)

      return (
        <GatsbyImage
          style={{ width: "100%" }}
          image={imageData}
          alt={title || "cover"}
        />
      )
    }

    if (typeof imageSource === "string") {
      return (
        <img src={imageSource || defaultTheme.defaultLandscape} alt={title} />
      )
    }

    return imageSource
  }, [imageSource])

  return (
    <Row
      gutter={26}
      className="author-row"
      onClick={() => {
        navigate(`/${translatePath("author")}/${seo.slug}`)
      }}
    >
      <Col {...imageColSettings}>
        <div className="image-container">{coverElement}</div>
      </Col>
      <Col {...contentColSettings}>
        <h4 className="secondary">{title}</h4>
        <h2 className="title">{`${firstname} ${lastname}`}</h2>
        <div>
          <p className="description">{short_biography}</p>
        </div>
        <div className="extra-wrapper">
          <Space>
            <VideoCount count={video_rels?.length ?? 0} />
          </Space>
        </div>
      </Col>
    </Row>
  )
}

const AuthorListDetail = ({ data }) => {
  devLog({ data })

  return (
    <Space direction="vertical" className="author-list-detail" size={50}>
      {data.map(item =>
        item ? <AuthorRow key={item.id} data={item} /> : null
      )}
    </Space>
  )
}

export default AuthorListDetail
